import { HomeSectionFive, Souscription } from "../components"

const Processus = () => {
  return (
    <div>
      {/* <ProcessCover/> */}
      <Souscription/>
      <HomeSectionFive/>
    </div>
  )
}

export default Processus