import { Article, Bannier } from "../components"
const Blogue = () => {
  return (
    <div>
      <Bannier/>
      <Article/>
    </div>
  )
}

export default Blogue